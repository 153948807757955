import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import App from './../app.jsx';
import DashboardV1 from './../pages/dashboard/dashboard-v1.js';
import DashboardV2 from './../pages/dashboard/dashboard-v2.js';
import DashboardV3 from './../pages/dashboard/dashboard-v3.js';
import EmailInbox from './../pages/email/email-inbox.js';
import EmailCompose from './../pages/email/email-compose.js';
import EmailDetail from './../pages/email/email-detail.js';
import Widgets from './../pages/widget/widget.js';
import UIGeneral from './../pages/ui/ui-general.js';
import UITypography from './../pages/ui/ui-typography.js';
import UITabsAccordion from './../pages/ui/ui-tabs-accordion.js';
import UIModalNotification from './../pages/ui/ui-modal-notification.js';
import UIWidgetBoxes from './../pages/ui/ui-widget-boxes.js';
import UIMediaObject from './../pages/ui/ui-media-object.js';
import UIButtons from './../pages/ui/ui-buttons.js';
import UIIconDuotone from './../pages/ui/ui-icon-duotone.js';
import UIIconFontAwesome from './../pages/ui/ui-icon-fontawesome.js';
import UIIconBootstrap from './../pages/ui/ui-icon-bootstrap.js';
import UIIconSimpleLineIcons from './../pages/ui/ui-icon-simple-line-icons.js';
import UILanguageBarIcon from './../pages/ui/ui-language-bar-icon.js';
import UISocialButtons from './../pages/ui/ui-social-buttons.js';
import Bootstrap5 from './../pages/bootstrap/bootstrap-5.js';
import FormReg from './../pages/form/form-reg.js';
import FormElements from './../pages/form/form-elements.js';

import FormPlugins from './../pages/form/form-plugins.js';
import FormWizards from './../pages/form/form-wizards.js';
import TableElements from './../pages/table/table-elements.js';
import TablePlugins from './../pages/table/table-plugins.js';
import PosCustomerOrder from './../pages/pos/customer-order.js';
import PosKitchenOrder from './../pages/pos/kitchen-order.js';
import PosCounterCheckout from './../pages/pos/counter-checkout.js';
import PosTableBooking from './../pages/pos/table-booking.js';
import PosMenuStock from './../pages/pos/menu-stock.js';
import ChartJS from './../pages/chart/chart-js.js';
import ChartApex from './../pages/chart/chart-apex.js';
import Calendar from './../pages/calendar/calendar.js';
import Map from './../pages/map/map.js';
import Gallery from './../pages/gallery/gallery.js';
import PageBlank from './../pages/option/page-blank.js';
import PageWithFooter from './../pages/option/page-with-footer.js';
import PageWithFixedFooter from './../pages/option/page-with-fixed-footer.js';
import PageWithoutSidebar from './../pages/option/page-without-sidebar.js';
import PageWithRightSidebar from './../pages/option/page-with-right-sidebar.js';
import PageWithMinifiedSidebar from './../pages/option/page-with-minified-sidebar.js';
import PageWithTwoSidebar from './../pages/option/page-with-two-sidebar.js';
import PageFullHeight from './../pages/option/page-full-height.js';
import PageWithWideSidebar from './../pages/option/page-with-wide-sidebar.js';
import PageWithLightSidebar from './../pages/option/page-with-light-sidebar.js';
import PageWithMegaMenu from './../pages/option/page-with-mega-menu.js';
import PageWithTopMenu from './../pages/option/page-with-top-menu.js';
import PageWithBoxedLayout from './../pages/option/page-with-boxed-layout.js';
import PageWithMixedMenu from './../pages/option/page-with-mixed-menu.js';
import PageBoxedLayoutWithMixedMenu from './../pages/option/page-boxed-layout-with-mixed-menu.js';
import PageWithTransparentSidebar from './../pages/option/page-with-transparent-sidebar.js';
import PageWithSearchSidebar from './../pages/option/page-with-search-sidebar.js';
import ExtraTimeline from './../pages/extra/extra-timeline.js';
import ExtraComingSoon from './../pages/extra/extra-coming-soon.js';
import ExtraSearch from './../pages/extra/extra-search.js';
import ExtraInvoice from './../pages/extra/extra-invoice.js';
import ExtraError from './../pages/extra/extra-error.js';
import ExtraProfile from './../pages/extra/extra-profile.js';
import ExtraScrumBoard from './../pages/extra/extra-scrum-board.js';
import ExtraCookieAcceptanceBanner from './../pages/extra/extra-cookie-acceptance-banner.js';
import ExtraOrders from './../pages/extra/extra-orders.js';
import ExtraOrderDetails from './../pages/extra/extra-order-details.js';
import ExtraProducts from './../pages/extra/extra-products.js';
import ExtraProductDetails from './../pages/extra/extra-product-details.js';
import ExtraFileManager from './../pages/extra/extra-file-manager.js';
import ExtraPricingPage from './../pages/extra/extra-pricing-page.js';
import ExtraMessengerPage from './../pages/extra/extra-messenger-page.js';
import ExtraDataManagement from './../pages/extra/extra-data-management.js';
import ExtraSettingsPage from './../pages/extra/extra-settings-page.js';
import LoginV1 from './../pages/user/login-v1.js';
import LoginV2 from './../pages/user/login-v2.js';
import LoginV3 from './../pages/user/login-v3.js';
import RegisterV3 from './../pages/user/register-v3.js';
import PasswordRecovery from './../pages/user/recoverPass.js';
import ResetPws from './../pages/user/resetPws.js';
import HelperCSS from './../pages/helper/helper-css.js';

import ViewFiltersElements from './../pages/viewmaster/filters-filters.js';
import NewFilterElements from './../pages/viewmaster/filters-newfilter.js';
import ViewRatePoints from './../pages/rate_points/rate_points-view.js';
import NewRatePoint from './../pages/rate_points/rate_points-insert.js';
import ViewRatePointsUsed from './../pages/rate_points/rate_points-actives.js';
import NewFileUpElements from './../pages/detailEvents/detailEvents-upfile.js';
import ViewCategoryEvents from './../pages/detailEvents/detailEvents-viewcateg.js';
import InfoMapping from './../pages/mapping/mapping-info.js';
import ViewCitysMaped from './../pages/mapping/mapping-viewcity.js';
import Homepage from './../pages/dashboard/index-v0.js';
import InfoEventsMapping from './../pages/detailEvents/infoMapping-events.js';
import ViewFiltersMaped from './../pages/mapping/mapping-filters.js';
import InfoSalesTickets from './../pages/byu/byu-salestick.js';
import InfoSalesHotels from './../pages/byu/byu-saleshotels.js';
import ValuesTaxFormElements from './../pages/valuestax/valuestax-infovaluetax.js';
import RegUsersElements from './../pages/datausers/dataUsers-reg.js';
import PrivateRoute from './../context/privateRoute'; // Componente de ruta privada
import { AuthProvider } from './../context/contextValid'; // Proveedor de contexto de autenticación
import { getFromLocalStorage } from '../components/helps.js';

//cambios en esta parte
// componentMap o listComponent (definir nombre de variable) enlista todos los elementos o componentes a los que se puede llegar a tener acceso
// si se da de alta una nueva vista se debera importar y agregar a la variable
// endpoint donde devuelve unicamente los componentes a los que tiene acceso
// el endpoint debe devolver siempre los componentes que no necesitan session asi como la estructura del path, element y los childrens con sus respectivos path y element
const componentMap = {
	AuthProvider,
	PrivateRoute,
	DashboardV1,
	DashboardV2,
	DashboardV3,
	Homepage,
	ExtraError,
	
};
let menuRoutes = [{
	path: '',
	element: <Navigate to='/user/login-v1' />
}];
let routes = [{
	path: '*',
	element: <App />,
	children: []
}];

const menu = getFromLocalStorage("menus");
if (menu != undefined && menu != null && menu != '') {

	menu.forEach(element => {
		//let _path=element.path
		console.log(element);
		
	});

}

const AppRoute = [
	{
		path: '*',
		element: <App />,
		children: [
			{
				// path: '', 
				// element: <Navigate to='/dashboard/v3' />
				path: '',
				element: <Navigate to='/user/login-v1' />
			},
			{
				path: 'dashboard/*',
				element: (<AuthProvider><PrivateRoute><Outlet /></PrivateRoute></AuthProvider>),
				children: [
					{ path: 'v1', element: <DashboardV1 /> },
					{ path: 'v2', element: <DashboardV2 /> },
					{ path: 'v3', element: <DashboardV3 /> },
					{ path: 'v0', element: <Homepage /> },
					{ path: '*', element: <ExtraError /> }
				]
			},
			{
				path: 'email/*',
				element: (<AuthProvider><PrivateRoute><Outlet /></PrivateRoute></AuthProvider>),
				children: [
					{ path: 'inbox', element: <EmailInbox /> },
					{ path: 'compose', element: <EmailCompose /> },
					{ path: 'detail', element: <EmailDetail /> },
					{ path: '*', element: <ExtraError /> }
				]
			},
			{
				path: 'widgets',
				element: (<AuthProvider><PrivateRoute><Widgets /></PrivateRoute></AuthProvider>)
			},
			{
				path: 'ui/*',
				element: (<AuthProvider><PrivateRoute><Outlet /></PrivateRoute></AuthProvider>),
				children: [
					{ path: 'general', element: <UIGeneral /> },
					{ path: 'typography', element: <UITypography /> },
					{ path: 'tabs-accordion', element: <UITabsAccordion /> },
					{ path: 'modal-notification', element: <UIModalNotification /> },
					{ path: 'widget-boxes', element: <UIWidgetBoxes /> },
					{ path: 'media-object', element: <UIMediaObject /> },
					{ path: 'buttons', element: <UIButtons /> },
					{ path: 'icon-duotone', element: <UIIconDuotone /> },
					{ path: 'icon-fontawesome', element: <UIIconFontAwesome /> },
					{ path: 'icon-bootstrap', element: <UIIconBootstrap /> },
					{ path: 'icon-simple-line-icons', element: <UIIconSimpleLineIcons /> },
					{ path: 'language-bar-icon', element: <UILanguageBarIcon /> },
					{ path: 'social-buttons', element: <UISocialButtons /> },
					{ path: '*', element: <ExtraError /> }
				]
			},
			{
				path: 'bootstrap-5',
				element: (<AuthProvider><PrivateRoute><Bootstrap5 /></PrivateRoute></AuthProvider >)
			},
			{
				path: 'form/*',
				element: (<AuthProvider><PrivateRoute><Outlet /></PrivateRoute></AuthProvider>),
				children: [
					{ path: 'elements', element: <FormElements /> },			
					{ path: 'plugins', element: <FormPlugins /> },
					{ path: 'wizards', element: <FormWizards /> },
					{ path: 'reg', element: <FormReg /> },
					{ path: '*', element: <ExtraError /> }
				]
			},
			{
				path: 'table/*',
				element: (<AuthProvider><PrivateRoute><Outlet /></PrivateRoute></AuthProvider>),
				children: [
					{ path: 'elements', element: <TableElements /> },
					{ path: 'plugins', element: <TablePlugins /> },
					{ path: '*', element: <ExtraError /> }
				]
			},
			{
				path: 'pos/*',
				element: (<AuthProvider><PrivateRoute><Outlet /></PrivateRoute></AuthProvider>),
				children: [
					{ path: 'customer-order', element: <PosCustomerOrder /> },
					{ path: 'kitchen-order', element: <PosKitchenOrder /> },
					{ path: 'counter-checkout', element: <PosCounterCheckout /> },
					{ path: 'table-booking', element: <PosTableBooking /> },
					{ path: 'menu-stock', element: <PosMenuStock /> },
					{ path: '*', element: <ExtraError /> }
				]
			},
			{
				path: 'chart/*',
				element: (<AuthProvider><PrivateRoute><Outlet /></PrivateRoute></AuthProvider>),
				children: [
					{ path: 'js', element: <ChartJS /> },
					{ path: 'apex', element: <ChartApex /> },
					{ path: '*', element: <ExtraError /> }
				]
			},
			{
				path: 'calendar',
				element: (<AuthProvider><PrivateRoute><Calendar /></PrivateRoute></AuthProvider >)
			},
			{
				path: 'map',
				element: (<AuthProvider><PrivateRoute><Map /></PrivateRoute></AuthProvider>)
			},
			{
				path: 'gallery',
				element: (<AuthProvider><PrivateRoute><Gallery /></PrivateRoute></AuthProvider>)
			},
			{
				path: 'page-option/*',
				element: (<AuthProvider><PrivateRoute><Outlet /></PrivateRoute></AuthProvider>),
				children: [
					{ path: 'blank', element: <PageBlank /> },
					{ path: 'with-footer', element: <PageWithFooter /> },
					{ path: 'with-fixed-footer', element: <PageWithFixedFooter /> },
					{ path: 'without-sidebar', element: <PageWithoutSidebar /> },
					{ path: 'with-right-sidebar', element: <PageWithRightSidebar /> },
					{ path: 'with-minified-sidebar', element: <PageWithMinifiedSidebar /> },
					{ path: 'with-two-sidebar', element: <PageWithTwoSidebar /> },
					{ path: 'full-height', element: <PageFullHeight /> },
					{ path: 'with-wide-sidebar', element: <PageWithWideSidebar /> },
					{ path: 'with-light-sidebar', element: <PageWithLightSidebar /> },
					{ path: 'with-mega-menu', element: <PageWithMegaMenu /> },
					{ path: 'with-top-menu', element: <PageWithTopMenu /> },
					{ path: 'with-boxed-layout', element: <PageWithBoxedLayout /> },
					{ path: 'with-mixed-menu', element: <PageWithMixedMenu /> },
					{ path: 'boxed-layout-with-mixed-menu', element: <PageBoxedLayoutWithMixedMenu /> },
					{ path: 'with-boxed-layout', element: <PageWithBoxedLayout /> },
					{ path: 'with-transparent-sidebar', element: <PageWithTransparentSidebar /> },
					{ path: 'with-search-sidebar', element: <PageWithSearchSidebar /> },
					{ path: '*', element: <ExtraError /> }
				]
			},
			{
				path: 'extra/*',
				element: (<AuthProvider><PrivateRoute><Outlet /></PrivateRoute></AuthProvider>),
				children: [
					{ path: 'timeline', element: <ExtraTimeline /> },
					{ path: 'coming-soon', element: <ExtraComingSoon /> },
					{ path: 'search', element: <ExtraSearch /> },
					{ path: 'invoice', element: <ExtraInvoice /> },
					{ path: 'error', element: <ExtraError /> },
					{ path: 'profile', element: <ExtraProfile /> },
					{ path: 'scrum-board', element: <ExtraScrumBoard /> },
					{ path: 'cookie-acceptance-banner', element: <ExtraCookieAcceptanceBanner /> },
					{ path: 'orders', element: <ExtraOrders /> },
					{ path: 'order-details', element: <ExtraOrderDetails /> },
					{ path: 'products', element: <ExtraProducts /> },
					{ path: 'product-details', element: <ExtraProductDetails /> },
					{ path: 'file-manager', element: <ExtraFileManager /> },
					{ path: 'pricing-page', element: <ExtraPricingPage /> },
					{ path: 'messenger-page', element: <ExtraMessengerPage /> },
					{ path: 'data-management', element: <ExtraDataManagement /> },
					{ path: 'settings-page', element: <ExtraSettingsPage /> },
					{ path: '*', element: <ExtraError /> }
				]
			},
			{
				path: 'user/*',
				element: <Outlet />,
				children: [
					{ path: 'login-v1', element: <LoginV1 /> },
					{ path: 'login-v2', element: <LoginV2 /> },
					{ path: 'login-v3', element: <LoginV3 /> },
					{ path: 'register-v3', element: <RegisterV3 /> },
					{ path: '*', element: <ExtraError /> },
					{ path: 'recoverPass', element: <PasswordRecovery /> },
					{ path: 'resetPWS', element: <ResetPws /> }

				]
			},
			{
				path: 'helper/css',
				element: (<AuthProvider><PrivateRoute><HelperCSS /></PrivateRoute></AuthProvider>)
			},
			{
				path: 'viewmaster/*',
				element: (<AuthProvider><PrivateRoute><Outlet /></PrivateRoute></AuthProvider>),
				children: [
					{ path: 'newfilter', element: <NewFilterElements /> },

					{ path: 'filters', element: <ViewFiltersElements /> },
				]
			},
			{
				path: 'rate_points/*',
				element: (<AuthProvider><PrivateRoute><Outlet /></PrivateRoute></AuthProvider>),
				children: [
					{ path: 'view', element: <ViewRatePoints /> },
					{ path: 'insert', element: <NewRatePoint /> },
					{ path: 'actives', element: <ViewRatePointsUsed /> },


					// { path: 'filters', element: <ViewFiltersElements /> },
				]
			},
			{
				path: 'detailEvents/*',
				element: (<AuthProvider><PrivateRoute><Outlet /></PrivateRoute></AuthProvider>),
				children: [
					{ path: 'upfile', element: <NewFileUpElements /> },
					{ path: 'viewcateg', element: <ViewCategoryEvents /> },
					{ path: 'events', element: <InfoEventsMapping /> },


				]
			},
			{
				path: 'mapping/*',
				element: (<AuthProvider><PrivateRoute><Outlet /></PrivateRoute></AuthProvider>),
				children: [
					{ path: 'info', element: <InfoMapping /> },
					{ path: 'viewcity', element: <ViewCitysMaped /> },
					{ path: 'filters', element: <ViewFiltersMaped /> },


				]
			},
			{
				path: 'byu/*',
				element: (<AuthProvider><PrivateRoute><Outlet /></PrivateRoute></AuthProvider>),
				children: [
					{ path: 'salestick', element: <InfoSalesTickets /> },
					{ path: 'saleshotels', element: <InfoSalesHotels /> },

				]
			},
			{
				path: 'valuestax/*',
				element: (<AuthProvider><PrivateRoute><Outlet /></PrivateRoute></AuthProvider>),
				children: [
					{ path: 'infovaluetax', element: <ValuesTaxFormElements /> },
				]
			},
			{
				path: 'datausers/*',
				element: (<AuthProvider><PrivateRoute><Outlet /></PrivateRoute></AuthProvider>),
				children: [
					{ path: 'reg', element: <RegUsersElements /> },
				]
			},

			{ path: '*', element: <ExtraError /> }
		]
	}
];

export default AppRoute;