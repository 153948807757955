import React, { useContext, useState } from 'react';
import { useResolvedPath, useMatch, NavLink, useLocation, matchPath } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import MenuFinal from './../../config/app-menuPermisos.jsx';
import menus from './../../config/app-menu.jsx';
import menus2 from './../../config/app-menu2.jsx';

import { saveToLocalStorage, getFromLocalStorage } from '../../components/helps.js';

function NavItem({ menu, ...props }: LinkProps) {
	let resolved = useResolvedPath(menu.path);
	let match = useMatch({ path: resolved.pathname });
	let location = useLocation();
	let match2 = matchPath({ path: menu.path, end: false }, location.pathname);

	let icon = menu.icon && <div className="menu-icon"><i className={menu.icon}></i></div>;
	let img = menu.img && <div className="menu-icon-img"><img src={menu.img} alt="" /></div>;
	let caret = (menu.children && !menu.badge) && <div className="menu-caret"></div>;
	let label = menu.label && <span className="menu-label ms-5px">{menu.label}</span>;
	let badge = menu.badge && <div className="menu-badge">{menu.badge}</div>;
	let highlight = menu.highlight && <i className="fa fa-paper-plane text-theme"></i>;
	let title = menu.title && <div className="menu-text">{menu.title} {label} {highlight}</div>;
	//console.log(MenuFinal);
	return (
		<div className={'menu-item' + ((match || match2) ? ' active' : '') + (menu.children ? ' has-sub' : '')}>
			<NavLink className="menu-link" to={menu.path} {...props}>
				{img} {icon} {title} {caret} {badge}
			</NavLink>

			{menu.children && (
				<div className="menu-submenu">
					{menu.children.map((submenu, i) => (
						<NavItem key={i} menu={submenu} />
					))}
				</div>
			)}
		</div>
	);
}

function SidebarNav() {
	const [admin, setAdmin] = useState(getFromLocalStorage('adm') || false);
	const context = useContext(AppSettings);

	function handleSidebarSearch(e) {
		let targetValue = e.target.value.toLowerCase();

		if (targetValue) {
			let elms = Array.from(document.querySelectorAll('.app-sidebar:not(.app-sidebar-end) .menu > .menu-item:not(.menu-profile):not(.menu-header):not(.menu-search), .app-sidebar:not(.app-sidebar-end) .menu-submenu > .menu-item'));
			elms.forEach(elm => elm.classList.add('d-none'));

			let elms2 = Array.from(document.querySelectorAll('.app-sidebar:not(.app-sidebar-end) .has-text'));
			elms2.forEach(elm => elm.classList.remove('has-text'));

			let elms3 = Array.from(document.querySelectorAll('.app-sidebar:not(.app-sidebar-end) .expand'));
			elms3.forEach(elm => elm.classList.remove('expand'));

			let elms4 = Array.from(document.querySelectorAll('.app-sidebar:not(.app-sidebar-end) .menu > .menu-item:not(.menu-profile):not(.menu-header):not(.menu-search) > .menu-link, .app-sidebar .menu-submenu > .menu-item > .menu-link'));
			elms4.forEach(elm => {
				let targetText = elm.textContent.toLowerCase();
				if (targetText.includes(targetValue)) {
					let targetElm = elm.closest('.menu-item');
					targetElm.classList.remove('d-none');
					targetElm.classList.add('has-text');

					let targetElm2 = elm.closest('.menu-item.has-sub');
					if (targetElm2) {
						let targetElm3 = targetElm.querySelector('.menu-submenu .menu-item.d-none');
						if (targetElm3) {
							targetElm3.classList.remove('d-none');
						}
					}

					let targetElm4 = elm.closest('.menu-submenu');
					if (targetElm4) {
						targetElm4.style.display = 'block';

						let targetElm5 = targetElm.querySelector('.menu-item:not(.has-text)');
						if (targetElm5) {
							targetElm5.classList.add('d-none');
						}

						let targetElm6 = elm.closest('.has-sub:not(.has-text)');
						if (targetElm6) {
							targetElm6.classList.remove('d-none');
							targetElm6.classList.add('expand');

							let targetElm7 = targetElm.closest('.has-sub:not(.has-text)');
							if (targetElm7) {
								targetElm7.classList.remove('d-none');
								targetElm7.classList.add('expand');
							}
						}
					}
				}
			});
		} else {
			let elms5 = Array.from(document.querySelectorAll('.app-sidebar:not(.app-sidebar-end) .menu > .menu-item:not(.menu-profile):not(.menu-header):not(.menu-search).has-sub .menu-submenu'));
			elms5.forEach(elm => elm.removeAttribute('style'));

			let elms6 = Array.from(document.querySelectorAll('.app-sidebar:not(.app-sidebar-end) .menu > .menu-item:not(.menu-profile):not(.menu-header):not(.menu-search)'));
			elms6.forEach(elm => elm.classList.remove('d-none'));

			let elms7 = Array.from(document.querySelectorAll('.app-sidebar:not(.app-sidebar-end) .menu-submenu > .menu-item'));
			elms7.forEach(elm => elm.classList.remove('d-none'));

			let elms8 = Array.from(document.querySelectorAll('.app-sidebar:not(.app-sidebar-end) .expand'));
			elms8.forEach(elm => elm.classList.remove('expand'));
		}
	}

	//console.log('valor en sidebar <<<<<<<<<<<<<<<<<<<<<<');
	//console.log(admin);
	
	//const menusToShow = menus ;
	const menusToShow = getFromLocalStorage("menus") !== undefined && getFromLocalStorage("menus") != null && getFromLocalStorage("menus") !== "" ? getFromLocalStorage("menus") : menus;
	//const menusToShow = admin ? menus : menus2;
	//console.log('menuuuuuuuuuuuuuuuuuuuuuuuuuuuuuussssssssssss->',menusToShow);
	//console.log('STORAGEEEmenuuuuuuuuuuuuuuuuuuuuuuuuuuuuuussssssssssss->', getFromLocalStorage("menus"));

	return (
		<div className="menu">
			{context.appSidebarSearch && (
				<div className="menu-search mb-n3">
					<input type="text" className="form-control" placeholder="Sidebar menu filter..." onKeyUp={handleSidebarSearch} />
				</div>
			)}
			<div className="menu-header">Navigation</div>
			{menusToShow.map((menu, i) => (
				<NavItem key={i} menu={menu} />
			))}
		</div>
	);
}

export default SidebarNav;
