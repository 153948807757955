import React, { useState, useEffect } from "react";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";
import { upFileprocesss } from "./../../utils/urls.js";
import { ReactNotifications, Store } from "react-notifications-component";
import { FormsTypeusers, inputsSelected } from "./../../utils/urls.js";

const formFields = [
  { label: "First Name", type: "text", className: "form-control mb-5px" },
  {
    label: "Last Name ",
    type: "text",
    className: "form-control mb-5px",
  },
  { label: "Email", type: "email", className: "form-control mb-5px" },
  {
    label: "Landline (with country code)",
    type: "tel",
    className: "form-control mb-5px",
  },
  {
    label: "Mobile Phone (with country code)",
    type: "tel",
    className: "form-control mb-5px",
  },
  { label: "Agency Name", type: "file", className: "form-control mb-5px" },
  { label: "Logo", type: "file", className: "form-control mb-5px" },
  { label: "City or State", type: "text", className: "form-control mb-5px" },
  { label: "RNT", type: "text", className: "form-control mb-5px" },
  { label: "RNT File", type: "file", className: "form-control mb-5px" },
  {
    label: "Tax ID or Foreign Equivalent",
    type: "text",
    className: "form-control mb-5px",
  },
  {
    label: "Tax ID File or Foreign Equivalent",
    type: "file",
    className: "form-control mb-5px",
  },
  {
    label: "Are you affiliated with AMAV?",
    type: "checkbox",
    className: "form-check-input",
  },
  { label: "Agency CLABE", type: "text", className: "form-control mb-5px" },
  {
    label: "Agency CLABE File",
    type: "file",
    className: "form-control mb-5px",
  },
  {
    label: "Company Name",
    type: "select",
    className: "form-select mb-5px",
    options: ["Tukmein", "Triptech"], // Subarray con opciones
  },
  { label: "Role", type: "text", className: "form-control mb-5px" },
  {
    label: "Type of discharge",
    type: "checkbox",
    className: "form-check-input",
  },

  // Checkbox con clase personalizada

  // agregar valores adicionales para la tabla id_input (text)
  //  si es requerido o no 
  // agregar bandera para que en formulario sea obligatorio o no llenar 
  // columna de style 
  // modelo 

  {
    label: "Company Name",
    id_input: "id_identificador",
    type: "select",
    mandatory: true,
    style: "",
    className: "form-select mb-5px",
    options: ["Tukmein", "Triptech"],
    modelo: "",
  },

  // nombrar tabla como objetos web con el listado de todos los inputs posibles 
  // crear tabla formularios / para crear la cantidad de formularios posibles 
  // formularios objetos web  //  utilizar el id de formulario + id de input´s 



];

function RegUsersElements() {
  const [CategoryName, setCategoryName] = useState("");
  const [DataFile, setDataFile] = useState(null);
  // const [tipoFiltro, setTipoFiltro] = useState("0");
  const [formValues, setFormValues] = useState({});
  const [data, setData] = useState([]);
  const [DataInputs, setDataInputs] = useState([]);

  const [tipoFiltro, setTipoFiltro] = useState('');


  useEffect(() => {
    // Solo ejecuta la petición si `data` está vacío
    if (data.length === 0) {
      fetch(FormsTypeusers())
        .then((response) => response.json())
        .then((data) => {
          console.log('lista de formularios a mostrar');
          setData(data.data); // Actualiza el estado con los datos recibidos
        })
        .catch((error) => console.error(error));
    }
  }, [data]);

  const handleOnChangeCreateRegist = async () => {
    try {
      console.log("entro a funcion para insert");
      const urlnew = upFileprocesss();

      const formdata = new FormData();
      formdata.append("file", DataFile);
      formdata.append("nameCategory", CategoryName);

      const response = await fetch(urlnew, {
        method: "POST",
        body: formdata,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("respuesta de insert");
      console.log(data);
      addNotification(
        "success",
        "Success",
        "All your data has been successfully updated",
        "top-center",
        false
      );
    } catch (error) {
      console.error("Error:", error);
      addNotification(
        "warning",
        "Error",
        "File not saved",
        "top-center",
        false
      );
      setDataFile(null);
      setCategoryName("");
    }
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setTipoFiltro(selectedValue);
    console.log(`Valor seleccionado: ${selectedValue}`);

    handleToggle(selectedValue);
  };

  const handleToggle = (idformSelect) => {

    try {
      fetch(inputsSelected(idformSelect))
        .then((response) => response.json())
        .then((data) => {
          console.log('lista de inputs a mostrar   ')
          // console.log(data);

          setDataInputs(data.data);
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.log('error en peticion. <<')
    }


  };

  // Notificacion
  function addNotification(
    notificationType,
    notificationTitle,
    notificationMessage,
    notificationPosition,
    notificationContent
  ) {
    if (notificationContent) {
      notificationContent = (
        <div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
          <div className="flex-1 ps-2">
            <h6 className="mb-1">Christopher Struth</h6>
            <p className="mb-0">Bank Transfer</p>
          </div>
        </div>
      );
    }
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: notificationPosition,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true },
      content: notificationContent,
    });
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted", formValues);
  };


  // console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<data>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')

  // console.log(data)

  // console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Valores de inputs a utilizar ')
  // console.log(DataInputs);


  return (
    <div>
      <div className="row mb-3">
        <div className="col-xl-6">
          <Panel>
            <PanelHeader>Registers New Users</PanelHeader>

            <PanelBody>


              <div className="row mb-15px">
                <label className="form-label col-form-label col-md-3">
                  Type Search
                </label>
                <div className="col-md-9">
                  <select
                    className="form-select"
                    value={tipoFiltro}
                    onChange={handleSelectChange}
                  >
                    <option value="0">Seleccione una opción</option>
                    {data.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.type_user}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <form onSubmit={handleSubmit}>
                {DataInputs.map((field, index) => (
                  <div className="row mb-15px" key={index}>
                    <label className="form-label col-form-label col-md-3" htmlFor={field.id_input_text}>
                      {field.label}
                      {field.mandatory && <span style={{ color: "red" }}> *</span>}
                    </label>
                    <div className="col-md-9">
                      {field.type === "select" ? (
                        <select
                          id={field.id_input_text}
                          className={field.classname}
                          name={field.id_input_text}
                          onChange={handleChange}
                        >
                          <option value="">Select an option</option>
                          {field.options.map((option, idx) => (
                            <option key={idx} value={option.name_option}>
                              {option.name_option}
                            </option>
                          ))}
                        </select>
                      ) : field.type === "checkbox" ? (
                        <input
                          type={field.type}
                          id={field.id_input_text}
                          className={field.classname}
                          name={field.id_input_text}
                          onChange={handleChange}
                          checked={field.modelo || false}
                        />
                      ) : (
                        <input
                          type={field.type}
                          id={field.id_input_text}
                          className={field.classname}
                          name={field.id_input_text}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                  </div>
                ))}
                <div className="row">
                  <div className="col-md-7 offset-md-3">
                    <button type="submit" className="btn btn-primary w-100px me-5px">
                      Create
                    </button>
                  </div>
                </div>
              </form>

            </PanelBody>
          </Panel>
        </div>
      </div>
      <ReactNotifications />{" "}
      {/* Ensure ReactNotifications component is included */}
    </div>
  );
}

export default RegUsersElements;