import React, { useEffect, useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { resetPasswor } from '../../utils/urls.js';
import axios from "axios";
import { saveToLocalStorage, getFromLocalStorage } from '../../components/helps.js';
import { ReactNotifications, Store } from 'react-notifications-component';
import CryptoJS from 'crypto-js';
import ReCAPTCHA from "react-google-recaptcha";


const secretKey = process.env.REACT_APP_SECRET_KEY;

const ResetPws =()=> {
	const [captcha, setCaptcha] = useState('');
	const [redirect, setRedirect] = useState(false);
	const [tipeuser, settipeuser] = useState(false);
	const [newAccess, setNewAccess] = useState({
		pwd: "",
		repitpwd: ""
	});

	const context = useContext(AppSettings);

	const handleChange = (e) => {
		setNewAccess({
			...newAccess,
			[e.target.name]: e.target.value
		});
	};

	const encryptData = (data) => {
		return CryptoJS.AES.encrypt(data, secretKey).toString();
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!captcha) {
			addNotification('warning', 'Error', 'Por favor, completa el reCAPTCHA.', 'top-center', false);
			return;
		}

		if (newAccess.pwd !== newAccess.repitpwd) {
			addNotification('warning', 'Error', 'Las contraseñas no coinciden.', 'top-center', false);
			return;
		}
		if( !getFromLocalStorage('tempUser')){
			return <Navigate to='/user/login-v1' />;
		}
		try {
			const encryptedpwds = encryptData(newAccess.pwd);
			const encryptedreppwds = encryptData(newAccess.repitpwd);
			const tokenGoogle = encryptData(captcha);
			const encryuser = encryptData(getFromLocalStorage('tempUser'));

			const response = await axios.post(resetPasswor(), {
				newPwds: encryptedpwds,
				repPwds: encryptedreppwds,
				captcha: tokenGoogle,
				tempUser : encryuser
			});
			console.log(response);
			let status = response.data
			if (response.status == 201) {
				setRedirect(true);
				saveToLocalStorage('TK', status.TK);
				settipeuser(status.admin);
			} else {
				addNotification('warning', 'Error', 'Datos no corectos', 'top-center', false);
			}
		} catch (error) {
			addNotification('warning', 'Error', 'Error: ' + error.response.data.message,'top-center', false);
		}
	};
	useEffect(() => {
		context.handleSetAppHeaderNone(true);
		context.handleSetAppSidebarNone(true);
		context.handleSetAppContentClass('p-0');
		saveToLocalStorage('TK', '');
		return function cleanUp() {
			context.handleSetAppHeaderNone(false);
			context.handleSetAppSidebarNone(false);
			context.handleSetAppContentClass('');
		};
	}, []);

	if (redirect) {
		// return <Navigate to='/dashboard/v0' />;
		if (tipeuser) {
			return <Navigate to='/dashboard/v0' />;
		} else {
			return <Navigate to='/detailEvents/viewcateg' />;
		}
	}


	function addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition, notificationContent) {
		if (notificationContent) {
			notificationContent = (
				<div className="d-flex align-items-center bg-gray-900 rounded p-2 text-white w-100">
					<div className="flex-1 ps-2">
						<h6 className="mb-1">Christopher Struth</h6>
						<p className="mb-0">Bank Transfer</p>
					</div>
				</div>
			);
		}
		Store.addNotification({
			title: notificationTitle,
			message: notificationMessage,
			type: notificationType,
			insert: "top",
			container: notificationPosition,
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: { duration: 2000 },
			dismissable: { click: true },
			content: notificationContent
		});
	}
	function onChange(e) {
		if (e) {
			setCaptcha(e);
		}
	}


	return (
		<div className="login login-v1">
			<div className="login-container">
				<div className="login-header">
					<div className="brand">
						<div className="d-flex align-items-center">
							<span className="logo"></span> <b>Color</b> Admin
						</div>
						{/* <small>Bootstrap 5 Responsive Admin Template</small> */}
					</div>
					<div className="icon">
						<i className="fa fa-lock"></i>
					</div>
				</div>
				<div className="login-body">
					<div className="login-content fs-13px">
						<form onSubmit={handleSubmit}>
							<div className="form-floating mb-20px">
								<input type="password" className="form-control fs-13px h-45px" name="pwd" placeholder="Nueva Contraseña" onChange={handleChange} />
								<label htmlFor="password" className="d-flex align-items-center py-0">Nueva Contraseña</label>
							</div>
							<div className="form-floating mb-20px">
								<input type="password" className="form-control fs-13px h-45px" name="repitpwd" placeholder="Nueva Contraseña" onChange={handleChange} />
								<label htmlFor="password" className="d-flex align-items-center py-0">Confirmar Contraseña</label>
							</div>
							<div className="form-floating mb-20px">
								<ReCAPTCHA sitekey="6LdObjUqAAAAAN8y8xtIzaaoxoNKon3mP9AGo60_" onChange={onChange} />
							</div>
							<div className="login-buttons">
								<button type="submit" className="btn h-45px btn-theme d-block w-100 btn-lg">Send</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<ReactNotifications /> {/* Ensure ReactNotifications component is included */}

		</div>
	)
}

export default ResetPws;