// const urlsend = 'https://tixtestback.co/';
const urlsend = 'https://nhsa.travel/';
const urlbackAngel = 'https://www.testunit00.co/';
const backTixtest = 'https://tixtestback.co/'

 const local = 'http://localhost:3000/';
 const redLocal  ='http://192.168.31.235:3000/';

/// Valida Sesion

export function getSession(token){
  //console.log('getSession ');
  //return `${local}sessionPermiss/validSession?token=${token}`;
   return `${urlsend}sessionPermiss/validSession?token=${token}`;
}

export function getMenuacces(token){
  //console.log('getSession ');
  //return `${local}sessionPermiss/validSession?token=${token}`;
  return `${urlsend}sessionPermiss/accesMenu?token=${token}`;
}

// recuperacion Contraseña
export function recoverAcces(){
  return `${urlsend}Authent/recoverAcces`
  // return `${local}Authent/recoverAcces`
}
export function resetPasswor(){
  return `${urlsend}Authent/resetPasswor`
  // return `${local}Authent/resetPasswor`
}

// obtener Menus
export function getMenus(tk) {
  //return `${urlsend}Authent/resetPasswor`
  return `${urlsend}Authent/menus?tk=${tk}`;
}
// obtener lista de modulos y submodulos
export function getModulesAndSubModules(tk) {
  return `${urlsend}admin_modules/allModules?token=${tk}`;
}
// obtener lista roles
export function getRoles(tk) {
  return `${urlsend}admin_roles/allRoles?token=${tk}`;
}
export function getModulesByUser(tk) {
  return `${urlsend}admin_modules/userModules?token=${tk}`;
}
export function putUserModules(tk) {
  return `${urlsend}admin_modules/userModules?token=${tk}`;
}

//Obtener todas las sucursales
export function getAllBranch(token){
  return `${urlsend}admin_branches/allBranches?token=${token}`;
}

//Obtener todas las Companies
export function getAllCompanies(token){
  return `${urlsend}admin_companies/allCompanies?token=${token}`;
}

//Editar Sucursal
export function putBranch(token){
  return `${urlsend}admin_branches/putBranch?token=${token}`;
}

//Editar Compania 
export function putCompany(token){
  return `${urlsend}admin_companies/putCompany?token=${token}`;
}

//Insertar Sucursal 
export function addBranch(token){
  return `${urlsend}add_branches/campanyData?token=${token}`;
}

//Insertar Compania 
export function addCompany(token){
  return `${urlsend}admin_companies/companyData?token=${token}`;
}

//Obtener sucursales de compania
export function getBranchDataByCompany(token){
  return `${urlsend}admin_branches/branchDataByCompany?token=${token}`;
}

//Consulta 
export function getBranch(token){
  return `${urlsend}admin_branches/branchData?token=${token}`;
}

//Consulta compania 
export function getCompany(token){
  return `${urlsend}admin_companies/companyData?token=${token}`;
}

//Consulta Usuarios por sucursal 
export function getUserBranch(token) {
  return `${urlsend}admin_data_warehouse/userByBranches?token=${token}`;
}


/// consultar filtros 
export function getgetfiltersbyidtype() {

   //console.log('getfiltersbyidtype ');
    return `${urlsend}tixtix/getfiltersbyidtype`;
  }



/// crear nuevo filtro filtros 

export function createnewfilter(tipofiltro, comentariotipe, namefiltro, activofiltro, idevento) {

 //console.log('getfiltersbyidtype ');
  return `${urlsend}tixtix/setnewtypefiltro?tipofiltro=${tipofiltro}&comentariotipe=${comentariotipe}&namefiltro=${namefiltro}&activofiltro=${activofiltro}&idevento=${idevento}`;
}



/// desactivar filtro 
  export function desactivivatefilter(idfiltro) {

   //console.log('getfiltersbyidtype ');
    return `${urlsend}tixtix/desactivivatefilter?idfiltro=${idfiltro}`;
  }


  /// Rate Points

  export function allRatePointsCompany() {
    return `${urlsend}crtRatePoints/rdAllratePoints?idcompay`;
  }

  export function NewRatePoint() {
    return `${urlsend}crtRatePoints/ratePointsCreat`;
  }

  export function EditRatePoint() {
    return `${urlsend}crtRatePoints/updatePoints`;
  }

  export function DeleteRatePoint(idratep) {
    return `${urlsend}crtRatePoints/deletratePoint?idratepoint=${idratep}`;
  }

  export function saveGetprices(precio1, precio2, tax1,flagspoints, newRatePoint, defaultMarkpoints, defaultCompoints) {
    return `${urlbackAngel}happypath/calculadora?precio1=${precio1}&precio2=${precio2}&tax1=${tax1}&flagspoints=${flagspoints}&newRatePoint=${newRatePoint}&defaultMarkpoints=${defaultMarkpoints}&defaultCompoints=${defaultCompoints}`;
  }


  /////// files csv
  export function upFileprocesss() {
    return `${urlsend}crtCsv/process`;
  }


  export function viewCategoryEvents () {
    return `${urlsend}crtCsv/rdAllinfotable`;
  }

  export function UpdateCategoryEvents () {
    return `${urlsend}crtCsv/UpdCategory`;
  }


  //// info peticiones markcorps
  export function infoMarkcorps () {
    return `${urlsend}happyPath/getstatusapi`;
  }
  
  export function infoMapping () {
    return `${urlsend}crtMapeo/infomapping`;
  }

  export function executerequestMapping (){
    return `${urlsend}crtMapeo/MapedMarkcorps`;
  }

  export function listCityStateCountry() {
    return `${urlsend}crtMapeo/listTextSearch`;
  }

  export function getinfoTableMap(valuepage) {
    return `${urlsend}crtMapeo/infoTableMap?vauepage=${valuepage}`;
  }

  export function getMappinEvents(valuepage) {
    return `${backTixtest}tixstockservices/geteventos`;
  }

  export function validAccessLogn() {
    //return `${urlsend}Authent/SignIn`;
    return `${urlsend}Authent/SignIn`;
  }

  export function validLogn() {
    return `${urlsend}Authent/AuthSession`;
  }


  export function infoStates(valuecountry) {
    return `${urlsend}crtMapeo/infoStatename?valuecountry=${valuecountry}`;
  }

  export function infoCityes(valueStateProv) {
    return `${urlsend}crtMapeo/infoCitys?valueStateProv=${valueStateProv}`;
  }


  export function infoStatestrue(valuecountry) {
    return `${urlsend}crtMapeo/infoStatenamemap?valuecountry=${valuecountry}`;
  }

  export function infoCityestrue(valueStateProv) {
    return `${urlsend}crtMapeo/infoCitysmap?valueStateProv=${valueStateProv}`;
  }


  export function postInfoHotelsMaped() {
    return `${urlsend}crtMapeo/mappStateCity`;
  }

  export function viewInfoValuesTax (valuestage) {
    return `${urlsend}crtValueTax/rdAllValueTax`;
  }

  export function updateinfoValueTax (valuestage) {
    return `${urlsend}crtValueTax/updateValueTax`;
  }


  export function FormsTypeusers () {
    return `${urlsend}datausers/listforms`;
  }

  export function inputsSelected (idform) {
    return `${urlsend}datausers/InputsForms?idforms=${idform}`;
  }

  