import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { getSession } from '../utils/urls.js';
import { saveToLocalStorage, getFromLocalStorage } from '../components/helps.js';
// Crear el contexto
const AuthContext = createContext();

// Proveedor del contexto
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState(['']);

    // Verificar el estado de autenticación al iniciar
    useEffect(() => {
        const checkAuth = async () => {
            const token = getFromLocalStorage('TK');
            //console.log('token', token);
            if (token) {
                try {
                    //cambios en esta parte
                    // obtener unicamente el token del localStorage, 
                    // endpoint para validar la sesion responde TRUE o FALSE nada mas
                    // endpoint para obtener los modulos que acepta, junto con el menu, no guarda en el local storage 

                    const response = getFromLocalStorage('Session');
                    console.log('response session->', response);
                    var autenticationValid = true;
                    if (response !== undefined && response != null) {
                        //console.log('response succes', response.data.data);
                        setPermissions(response.modulesAcept || []);

                    } else if (response.message) {
                        console.log('response error', response.message);
                        autenticationValid = false;
                        saveToLocalStorage('message', response.message);
                        saveToLocalStorage('TK', '');
                    }
                    setIsAuthenticated(autenticationValid);
                    // setPermissions(response.data.permissions || []);
                } catch (error) {
                    setIsAuthenticated(false);
                }
            }
            setLoading(false);
        };
        checkAuth();
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, permissions, loading, setIsAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};

// Hook para usar el contexto
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
