import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './contextValid'; // Asegúrate de tener un hook o contexto de autenticación

const PrivateRoute = ({ children }) => {
    const { isAuthenticated, permissions, loading } = useAuth();
    const location = useLocation();
    const currentPath = location.pathname;
    //console.log('isAuthenticated',isAuthenticated);
    //console.log('location', location);
    //console.log('currentPath', currentPath);
    //console.log('permissions', permissions);
    //console.log('!permissions.includes(currentPath)', !permissions.includes(currentPath));
    //console.log('children', children);
    if (loading) {
        return <div>Loading...</div>; // O cualquier spinner/loading component
    }
    if (!isAuthenticated) {
        return <Navigate to="/user/login-v1" state={{ from: location }} />;
    }
    if (!permissions.includes(currentPath)) {
        // Redirige si el usuario no tiene permisos para acceder a la ruta
        return <Navigate to="/dashboard/v0" />;
    }
    return children;
    
};

export default PrivateRoute;